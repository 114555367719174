






































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Page, PageCreate } from "@/models/Page";
import { TEMPLATE_REQUEST, TEMPLATE_LOADED } from "@/store/actions/template";
import {
	INDICATOR_TABLE_REQUEST,
	INDICATOR_TABLE_LOADED,
} from "@/store/actions/indicatorTable";
import { REGION_REQUEST, REGION_LOADED } from "@/store/actions/main";
import {
	PAGE_REQUEST,
	PAGE_UPDATE,
	PAGE_CU,
	PAGE_DELETE,
	PAGE_UPDATED,
	PAGE_LOADED,
} from "@/store/actions/page";
import { WIDGET_REQUEST } from "@/store/actions/widget";
import { REGION_REQUEST, REGION_LOADED } from "@/store/actions/main";
//import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import ChartPreview from "@/components/ChartPreview.vue";
import { EChartTypes } from "@/misc/Enums";

@Component({
	components: {
		//vueCustomScrollbar,
		ChartPreview,
	},
})
export default class PagesForm extends Vue {
	private regions: any = [];
	private templates: Template[] = [];
	private indicatorsTables: IndicatorTable[] = [];
	private renderForm: boolean = true;

	private form = {
		page_name: "",
		screenshot_base_64: "",
		page_widgets: [],
	} as any;
	private regions: any = [];

	private rows: any = 3;
	private cols: any = 4;

	private widgets: any = [];
	private widgetsHeaders = [
		{
			text: "ID",
			value: "id",
			align: "start",
			width: "5%",
		},
		{
			text: "Название",
			value: "widget_name",
			align: "start",
			width: "15%",
		},
		{
			text: "Регион",
			value: "region_id",
			align: "start",
			width: "15%",
		},
		{
			text: "Таблица индикаторов",
			value: "indicator_table_id",
			align: "start",
			width: "15%",
		},
		{
			text: "Шаблон",
			value: "template_id",
			align: "start",
			width: "15%",
		},
		{
			text: "Поля",
			value: "widget_fields.length",
			align: "start",
			width: "5%",
		},
		{
			text: "Верификация",
			value: "verified",
			align: "start",
			width: "5%",
		},
		{
			text: "Действия",
			value: "actions",
			align: "center",
			sortable: false,
			width: "15%",
		},
	];
	private widgetsDialog: boolean = false;
	private loading: boolean = false;
	private show: boolean = false;
	private search = "";
	private makingScreenshot: boolean = false;
	private options = {
		page: 0,
		itemsPerPage: -1,
	};
	private curRow = 0;
	private curCol = 0;
	private loadingBtn = false;
	//options
	private tablePage = 1;
	private tablePageCount = 0;
	private itemsPerPage = 15;
	//Scrollbar
	private scrollbarSettings = {
		suppressScrollY: false,
		suppressScrollX: false,
		wheelPropagation: false,
	};
	//Snackbar
	private messageText = "Не удалось выполнить";
	private messageColor = "red";
	private message = false;
	// проверка на то является ли значение числом
	private reg = /^[0-9,.]*$/;
	$refs!: {
		screen: any;
	};
	//Initialise methods
	private initIfReady() {
		//return true if started to init self.
		let needReload = [] as boolean[];
		needReload.push(
			this.needReload(
				this.$store.getters.WIDGET_TABLE,
				this.$store.getters.WIDGET_LOADED
			)
		);
		needReload.push(
			this.needReload(
				this.$store.getters.TEMPLATE_TABLE,
				this.$store.getters.TEMPLATE_LOADED
			)
		);
		needReload.push(
			this.needReload(
				this.$store.getters.REGION_TABLE,
				this.$store.getters.REGION_LOADED
			)
		);
		needReload.push(
			this.needReload(
				this.$store.getters.INDICATOR_TABLE_TABLE,
				this.$store.getters.INDICATOR_TABLE_LOADED
			)
		);
		for (let i = 0; i < needReload.length; i++) {
			if (needReload[i] == true) {
				return false;
			}
		}
		this.initSelf();
		return true;
	}
	private needReload(table, updated) {
		/*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
		if (table == null || !updated) return true;
		return false;
	}
	private tryToInitSelf() {
		this.loading = true;
		//const data = this.getParams();
		if (this.initIfReady()) return;

		if (
			this.needReload(
				this.$store.getters.WIDGET_TABLE,
				this.$store.getters.WIDGET_LOADED
			)
		) {
			this.$store.dispatch(WIDGET_REQUEST).then(() => {
				this.initIfReady();
			});
		}
		if (
			this.needReload(
				this.$store.getters.TEMPLATE_TABLE,
				this.$store.getters.TEMPLATE_LOADED
			)
		) {
			this.$store.dispatch(TEMPLATE_REQUEST).then(() => {
				this.initIfReady();
			});
		}
		if (
			this.needReload(
				this.$store.getters.REGION_TABLE,
				this.$store.getters.REGION_LOADED
			)
		) {
			this.$store.dispatch(REGION_REQUEST).then(() => {
				this.initIfReady();
			});
		}
		if (
			this.needReload(
				this.$store.getters.INDICATOR_TABLE_TABLE,
				this.$store.getters.INDICATOR_TABLE_LOADED
			)
		) {
			this.$store.dispatch(INDICATOR_TABLE_REQUEST).then(() => {
				this.initIfReady();
			});
		}
	}
	private initSelf() {
		console.log("INIT SELF");
		this.widgets = this.$store.getters.WIDGET_TABLE.data;
		this.templates = this.$store.getters.TEMPLATE_TABLE.data;
		this.regions = this.$store.getters.REGION_TABLE.data;
		this.indicatorsTables = this.$store.getters.INDICATOR_TABLE_TABLE.data;
		// if (localStorage.getItem("role") == "Verifer"){
		//   this.verifier = true;
		// }
		this.initStartOptions();
		this.loading = false;
		console.log("loading");
	}
	//Methods
	private openWidgetDialog(row, col) {
		this.curRow = row;
		this.curCol = col;
		this.widgetsDialog = true;
	}
	private addItem(item) {
		let exist = false;
		for (let i = 0; i < this.form.page_widgets.length; i++) {
			if (this.form.page_widgets.position == [this.curRow, this.curCol]) {
				exist = true;
				break;
			}
		}
		if (exist) {
			this.form.page_widgets[i] = {
				widget_id: item.id,
				position: [this.curRow, this.curCol],
			};
		} else {
			this.form.page_widgets.push({
				widget_id: item.id,
				position: [this.curRow, this.curCol],
			});
		}
		this.widgetsDialog = false;
		console.log("form:", this.form);
	}
	private editItem() {
		const el = this.$refs.screen;
		const options = {
			type: "dataURL",
		};
		this.makingScreenshot = true;
		this.$forceUpdate();
		setTimeout(async () => {
			let output = await this.$html2canvas(el, options);
			let img = output.replace(/^data:image\/(png|jpg);base64,/, "");
			this.form.screenshot_base_64 = img;
			this.makingScreenshot = false;
			console.log("out", output);
			console.log("form:", this.form);
			this.$forceUpdate();
			this.$store
				.dispatch(PAGE_CU, this.form)
				.then(() => {
					this.messageText = "Мозаика " + this.form.page_name + " изменена.";
					this.messageColor = "green";
				})
				.catch(() => {
					this.messageText = "Не удалось выполнить";
					this.messageColor = "red";
				})
				.finally(() => {
					this.message = true;
				});
		}, 1);
	}
	private async createItem() {
		const el = this.$refs.screen;
		const options = {
			type: "dataURL",
		};
		this.makingScreenshot = true;
		this.$forceUpdate();
		setTimeout(async () => {
			let output = await this.$html2canvas(el, options);
			let img = output.replace(/^data:image\/(png|jpg);base64,/, "");
			this.form.screenshot_base_64 = img;
			this.makingScreenshot = false;
			console.log("out", output);
			console.log("form:", this.form);
			this.$forceUpdate();
			this.$store
				.dispatch(PAGE_CU, this.form)
				.then(() => {
					this.messageText = "Мозаика " + this.form.page_name + " добавлена.";
					this.messageColor = "green";
				})
				.catch(() => {
					this.messageText = "Не удалось выполнить";
					this.messageColor = "red";
				})
				.finally(() => {
					this.message = true;
				});
		}, 1);
	}
	private getItem(row, col) {
		let pageWidgets = [] as any;
		if (
			typeof this.form.page_widgets != "undefined" &&
			this.form.page_widgets.length > 0
		)
			pageWidgets = this.form.page_widgets;
		let pos = 0;
		let exist = false;
		for (let i = 0; i < pageWidgets.length; i++) {
			if (
				pageWidgets[i].position[0] == row &&
				pageWidgets[i].position[1] == col
			) {
				pos = i;
				exist = true;
				break;
			}
		}
		if (exist) {
			for (let i = 0; i < this.widgets.length; i++) {
				if (pageWidgets[pos].widget_id == this.widgets[i].id) {
					return this.getWidgetItem(this.widgets[i]);
				}
			}
		}
		return null;
	}
	private getWidgetItem(widget: any) {
		let chartType;
		let previewLabels;
		let previewData;
		let previewName;
		let previewColors;
		let totalTitle;
		let totalValue;

		let labels = [] as any;
		let data = [] as any;
		let colors = [] as any;
		let template = {} as any;
		for (let i = 0; i < this.templates.length; i++) {
			if (widget.template_id == this.templates[i].id)
				template = this.templates[i];
		}
		let isExist = false;
		widget.widget_fields.forEach((item, key) => {
			if (item.template_field.key_name == "total") {
				isExist = true;
				totalTitle = item.field_title;
				totalValue = +item.field_value;
			} else {
				let isNumber = this.reg.test(item.field_value);
				console.log(item.field_value);
				console.log("isNumber", isNumber);

				labels.push(item.field_title);
				colors.push(item.key_color);
				if (isNumber) data.push(+item.field_value);
				else data.push(item.field_value);
			}
		});
		if (!isExist) {
			totalTitle = null;
			totalValue = null;
		}
		chartType = template.widget_type;
		previewColors = colors;
		previewLabels = labels;
		previewData = data;
		previewName = widget.widget_name;
		return {
			chartType: chartType,
			previewLabels: previewLabels,
			previewData: previewData,
			previewName: previewName,
			previewColors: previewColors,
			totalTitle: totalTitle,
			totalValue: totalValue,
		};
		//this.redrawChart();
	}
	private isItemExist(row, col) {
		if (this.getItem(row, col) == null) return false;
		return true;
	}
	private toString(str) {
		return str as string;
	}
	private isUpdatePage() {
		return this.$route.meta.isUpdate;
	}
	private initStartOptions() {
		var item;
		if (localStorage.getItem("pageToEdit")) {
			console.log("update page");
			try {
				item = JSON.parse(this.toString(localStorage.getItem("pageToEdit")));
				console.log("item init: ", item);
			} catch (e) {
				localStorage.removeItem("pageToEdit");
			}
		} else {
			console.log("create page");
		}
		/*
		Object.keys(item).forEach((key) => {
			this.form[key] = item[key];
		});
		*/
		//console.log(this.props.isUpdatePage);
		if (this.isUpdatePage()) {
			this.form.page_name = item.page_name;
			this.form.id = item.id;
			for (let i = 0; i < item.page_widgets.length; i++) {
				this.form.page_widgets.push({
					widget_id: item.page_widgets[i].widget_id,
					position: item.page_widgets[i].position,
				});
			}
			console.log("form: ", this.form);
		}
	}
	private getRegionName(id) {
		for (let i = 0; i < this.regions.length; i++) {
			if (this.regions[i].id == id) return this.regions[i].name;
		}
		return "";
	}
	private getIndicatorTableName(id) {
		for (let i = 0; i < this.indicatorsTables.length; i++) {
			if (this.indicatorsTables[i].id == id)
				return this.indicatorsTables[i].indicator_table_name;
		}
		return "";
	}
	private getTemplateName(id) {
		for (let i = 0; i < this.templates.length; i++) {
			if (this.templates[i].id == id) return this.templates[i].template_name;
		}
		return "";
	}
	private nameSearch(value, search, item): boolean {
		return (
			value != null &&
			search != null &&
			typeof value === "string" &&
			value
				.toString()
				.toLocaleLowerCase()
				.indexOf(search.toString().toLocaleLowerCase()) !== -1
		);
	}
	//Hooks
	private mounted() {
		this.tryToInitSelf();
	}
	private destroyed() {
		localStorage.removeItem("pagesToEdit");
	}
}
